<template>
  <Page class="bg-surface-100 pb-40">
    <save-bar :loading="loading" :isDirty="isDirty" @save="saveClick" @cancel="cancelClick" />
    <company-body
      @is-dirty="(b) => (isDirty = b)"
      @loading="loading = 1"
      @done="loading = 0"
      ref="body"
    />
  </Page>
</template>

<script>
import PageMixin from '@/components/mixins/Page'
import SaveBar from '@/components/pages/subnav/SaveBar.vue'
import CompanyBody from '@/components/bodies/Company.vue'

export default {
  mixins: [PageMixin],
  name: 'CompanyPage',
  data() {
    return {
      loading: 0,
      isDirty: 0
    }
  },
  methods: {
    getChanges() {},
    cancelClick() {
      this.$refs.body.reset()
    },
    saveClick() {
      this.$refs.body.saveChanges()
    }
  },
  components: {
    SaveBar,
    CompanyBody
  }
}
</script>
